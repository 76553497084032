.detail-head {
  font-size: 2.5rem;
  text-shadow: 2px 2px grey;
  color: #edcb96;
  margin: 0 0 25px 25px;
  max-height: 100%;
}

.system-details {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    "details logo"
    "details map"
    "details button";
  margin: 20px auto;
  max-width: 600px;
}

.detailsDiv {
  grid-area: details;
  text-align: left;
  margin: 0;
}

#details-title {
  margin: 0;
}

.detail {
  margin: 2px 0 2px 0;
}

.images {
  margin: 25px 25px 25px 70px;
  display: flex;
  flex-flow: column wrap;
}

#system-image {
  grid-area: logo;
  max-width: 250px;
  max-height: 250px;
}

#transit-map {
  grid-area: map;
  max-width: 250px;
  max-height: 250px;
}

#contribute-button {
  grid-area: button;
  margin: 20px;
}

.list {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
}

.reviews-section {
  margin: 25px 25px 50px 25px;
}

.review-head {
  margin: 0;
}

@media (max-width: 620px) {
  .detail-head {
    font-size: 150%;
    margin: 0 auto 10px auto;
  }

  .system-details {
    display: flex;
    flex-flow: column wrap;
    margin: 0;
  }

  .detailsDiv {
    margin: 10px;
  }

  #system-image,
  #transit-map {
    align-self: center;
    max-width: 200px;
    max-height: 200px;
    margin: 5px;
  }

  #contribute-button {
    margin: 20px;
  }
}
