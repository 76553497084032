.review-card {
  background-color: rgba(54, 82, 181, 0.75);
  border: 2px solid black;
  margin: 25px auto;
  max-width: 600px;
  padding: 10px;
  /* color: #edcb96; */
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-template-areas:
    "name date"
    "content content";
}

.review-card > h4 {
  grid-area: name;
  margin: 0;
  text-align: left;
}

.review-card > h5 {
  grid-area: date;
  margin: 0;
  display: inline-block;
  text-align: right;
}

.review-card > p {
  grid-area: content;
  margin: 0;
  font-size: 12px;
}
