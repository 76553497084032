#contribute-img {
  width: 100vw;
  height: 30vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
}

.contribute-head {
  font-size: 2.5rem;
}
.contribute-head-smol {
  font-size: 1.5rem;
}
.contribute-head,
.contribute-head-smol {
  text-shadow: 2px 2px grey;
  color: #edcb96;
  margin: 0 auto;
  max-height: 100%;
}

.contribute {
  text-align: right;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}

.contribute > form {
  width: 80%;
  max-width: 700px;
}

.label-input {
  margin: 0px;
}

.label-input > input {
  width: 50%;
  max-width: 400px;
}

.contribute > form > button {
  align-self: center;
}

@media (max-width: 740px) {
  .contribute {
    text-align: center;
  }

  .contribute > form {
    display: flex;
    flex-flow: column wrap;
  }

  .label-input {
    display: flex;
    text-align: center;
    flex-flow: column wrap;
    align-items: center;
  }
  .label-input > input {
    width: 80%;
  }

  .contribute > form > button {
    width: 80%;
    margin: 10px;
    text-align: center;
  }
}
