.footer {
  background-color: #3b60e4;
  color: #edcb96;
  display: flex;
  flex-flow: row no-wrap;
  align-items: center;
  width: 100vw;
  position: absolute;
  bottom: 0;
  font-size: 10px;
}

.footer > div {
  margin: 0 auto;
  display: grid;
  grid-template-areas:
    "fb sei ig"
    "fb copy ig";
}

.ga {
  grid-area: sei;
  margin: 0;
}

.copyright {
  grid-area: copy;
  margin: 0;
}

.socialIcon {
  /* display: inline-block; */
  height: 30px;
  width: 30px;
  align-self: center;
  margin: 2px;
}

#fb {
  grid-area: fb;
  margin: 0;
  height: 30px;
  width: 30px;
}
#ig {
  grid-area: ig;
  margin: 0;
  height: 30px;
  width: 30px;
}

@media (max-width: 1024px) {
}
@media (max-width: 500px) {
  .ga {
    display: none;
  }
  .copyright {
    align-self: center;
    margin: 0px 10px 0px 10px;
  }
  .footer > div {
    grid-template-areas: "fb copy ig";
  }
}
