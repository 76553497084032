body {
  background-color: #f7f2f8;
}

.App {
  text-align: center;
}

main {
  padding: 30px 0 0 0;
}

.header-img {
  width: 100vw;
  height: 25vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
}

footer {
  margin: 0;
  padding: 0;
  position: fixed;
  bottom: 0;
}

a {
  text-decoration: none;
}

a:link {
  color: #edcb96;
}

a:visited {
  color: #edcb96;
}

a:active {
  color: black;
}

a:hover {
  color: #edcb96;
}

@media (max-width: 500px) {
  main {
    padding: 0px 0 0 0;
  }
}
