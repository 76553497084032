.home-header-img {
  width: 100vw;
  height: 70vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
}

.home-title {
  margin: 0 0 25px 25px;
  color: #edcb96;
  font-size: 3rem;
  text-shadow: 2px 2px grey;
}

@media (max-width: 500px) {
  .home-title {
    margin: 0 auto 10px auto;
  }
}
