.overlay {
  /* transition: all 5s linear 5s; */
  border: 1px solid black;
  position: fixed;
  bottom: 35px;
  left: 15vw;
  height: 20vh;
  width: 70vw;
  background-color: rgba(247, 242, 248, 0.9);
  display: grid;
  grid-template-rows: 25% 75%;
  grid-template-columns: auto auto;
  grid-template-areas:
    "name button"
    "content content";

  /* flex-flow: column wrap;
  justify-content: center;
  align-content: flex-start; */
}

.full-name {
  grid-area: name;
  margin: 3px;
}

.content {
  grid-area: content;
  margin: 3px;
}

.submit {
  grid-area: button;
  margin: 3px;
}
