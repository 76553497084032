.system-card {
  background-color: rgba(54, 82, 181, 1);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 25px;
  width: 70%;
  height: 20vh;
  margin: 10px auto;
  padding: 10px;
  display: grid;
  grid-template-areas:
    "name location"
    "operatedby lines";
}

#name,
#location,
#operated-by,
#num-of-lines {
  justify-self: center;
  align-self: center;
  margin: 0;
  text-shadow: 2px 2px black;
}

#name {
  grid-area: name;
  font-size: 1.5rem;
}

#location {
  grid-area: location;
}

#operated-by {
  grid-area: operatedby;
}

#num-of-lines {
  grid-area: lines;
}

@media (max-width: 1000px) {
  .system-card {
    width: 90vw;
    height: 20vh;
  }
}

@media (max-width: 500px) {
  .system-card {
    display: grid;
    grid-template-areas: "name location";
  }

  #name {
    grid-area: name;
    font-size: 1.4rem;
    margin: 5px;
  }

  #location {
    grid-area: location;
    max-width: 100%;
    margin: 10px;
    justify-self: center;
    align-self: center;
  }

  #operated-by,
  #num-of-lines {
    display: none;
  }
}
