.nav {
  background-color: #3b60e4;
  color: #edcb96;
  display: flex;
  flex-flow: row nowrap;
  position: fixed;
  width: 100vw;
}

.nav-title {
  margin: 0 0 0 10px;
  text-decoration: none;
  align-self: flex-start;
}
.link-list {
  margin: 0;
  position: absolute;
  right: 0;
  top: 5px;
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 0;
  max-width: 80%;
}

.nav-link {
  margin: 10px;
  text-decoration: none;
}

.hamburger {
  height: 30px;
  width: 30px;
  position: absolute;
  right: 5px;
  top: 0;
  display: none;
}

@media (max-width: 1020px) {
  .link-list {
    display: flex;
    border: 2px solid black;
    flex-flow: column wrap;
    top: 30px;
    background-color: #3b60e4;
  }
  .hamburger {
    display: block;
  }
}

@media (max-width: 500px) {
  .nav-title {
    margin-left: 2px;
    text-align: left;
    font-size: 1rem;
  }

  .hamburger {
    width: 20px;
    height: 20px;
  }

  .link-list {
    top: 20px;
    font-size: 0.75rem;
  }
}
